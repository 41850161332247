import { updatePassword } from "../../../redux/authSlice";
import { FormItemArray } from "./formFields";

export const onClickHandler = (form) => {
  form.submit();
};

export const onFinish = (dispatch, navigate, values) => {
  const postData = {};
  FormItemArray.filter((item) => {
    return Object.keys(values).some((value) => value === item.name);
  }).map((item1) => {
    postData[item1.name] = values[item1.name];
  });
  dispatch(updatePassword({ updatePasswordInformation: postData, navigate }));
};
