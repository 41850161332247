import styled from "styled-components";

export const FilterArea = styled("div")`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-dropdown-trigger {
    border: 2px solid #434343;
    padding: 1em 2em;
    background: #000000;
    color: #ffffff;
    font-size: 1.1em;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      margin-left: 0.5em;
    }
  }
  @media (max-width: 1650px) {
    width: 75%;
    .ant-dropdown-trigger {
      padding: 0.5em 1em;
    }
  }
  @media (max-width: 1450px) {
    width: 80%;
    .ant-dropdown-trigger {
      padding: 0.5em;
      font-size: 1em;
      font-weight: 700;

      svg {
        margin-left: 0.5em;
      }
    }
  }
`;
