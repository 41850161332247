import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

// login
export const login = createAsyncThunk(
	"auth/login",
	async ({ loginData, navigate }, { dispatch, rejectWithValue }) => {
		try {
			const response = await ApiRequests.login(loginData);
			if (response.status === 200) {
				localStorage.setItem(
					"authUser",
					JSON.stringify(response?.data?.RESPONSE)
				); //setting user

				navigate("/");
				return response?.data?.RESPONSE;
			}
		} catch (error) {
			toast.error(error?.response?.data?.ERROR?.message, {
				position: "top-right",
				autoClose: 3000,
				closeOnClick: true,
				pauseOnHover: true,
				theme: "light",
			});
		}
	}
);

export const updatePassword = createAsyncThunk(
	"auth/password",
	async (
		{ updatePasswordInformation, navigate },
		{ dispatch, rejectWithValue }
	) => {
		try {
			const response = await ApiRequests.updatePassword(
				updatePasswordInformation
			);
			toast.warn(
				"Password was changed. after 3 seconds, the system will be logout",
				{
					position: "top-right",
					autoClose: 3000,
					onClose: () => {
						dispatch(logout());
						navigate("/login");
					},
					closeOnClick: true,
					pauseOnHover: true,
					theme: "light",
				}
			);
		} catch (error) {
			if (error?.response?.status === 422) {
				toast.error(error?.response?.data?.RESPONSE?.message, {
					position: "top-right",
					autoClose: 3000,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "light",
				});
			} else {
				toast.error(error?.response?.data?.ERROR?.message, {
					position: "top-right",
					autoClose: 3000,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "light",
				});
			}
		}
	}
);

// getUserProfile
export const getUserProfile = createAsyncThunk(
	"auth/getUserProfile",
	async (navigate, { dispatch, rejectWithValue }) => {
		try {
			const response = await ApiRequests.getUserProfile();
			return response?.data?.data;
		} catch (error) {
			if (
				error?.response?.data?.CODE === "UNAUTHORIZED" ||
				error?.response?.data?.CODE === "SESSION_EXPIRED" ||
				error?.response?.data?.CODE === "SESSION_EXPIRED"
			) {
				dispatch(logout());
				navigate("/login");
			}
			// toast.error(error?.response?.data?.errors?.[0]?.msg, {
			//   position: "top-right",
			//   autoClose: 3000,
			//   closeOnClick: true,
			//   pauseOnHover: true,
			//   theme: "light",
			// });
		}
	}
);

const initialState = {
	loading: false,
	updateProdileLoading: false,
	userProfile: {},
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logout(state) {
			localStorage.removeItem("authUser");
			state.userProfile = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.loading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(login.rejected, (state) => {
				state.loading = false;
			})

			.addCase(getUserProfile.pending, (state) => {
				state.loading = true;
			})
			.addCase(getUserProfile.fulfilled, (state, action) => {
				state.loading = false;
				state.userProfile = action.payload;
			})
			.addCase(getUserProfile.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default authSlice.reducer;
export const { logout } = authSlice.actions;
