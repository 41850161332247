import { APIurls } from "./constants";
import apiService from ".";

export const ApiRequests = {
	// auth
	login: async (data) => await apiService.post(APIurls.admin, data),
	getUserProfile: async () => await apiService.get(APIurls.adminProfile),

	// publishers
	addpublisher: async (data) => await apiService.post(APIurls.publishers, data),
	editPublisher: async (publisherId, data) =>
		await apiService.patch(APIurls.publishers + "/" + publisherId, data),
	getPublishers: async () => await apiService.get(APIurls.publishers),
	getPublisher: async (publisherId) =>
		await apiService.get(APIurls.publishers + "/" + publisherId),
	deletePublisher: async (publisherId) =>
		await apiService.delete(APIurls.publishers + "/" + publisherId),

	// settlements
	addSettlement: async (data) =>
		await apiService.post(APIurls.settlements, data),
	getAllSettlements: async () => await apiService.get(APIurls.settlements),
	deleteSettlement: async (settlementId) =>
		await apiService.delete(APIurls.settlements + "/" + settlementId),
	// transactions
	getTransactions: async () => await apiService.get(APIurls.transactions),
	updatePassword: async (data) =>
		await apiService.put(APIurls.updatePassword, data),

	// add articl price
	addArticlePrice: async (publisherId, data) =>
		await apiService.post(
			APIurls.publishers + "/" + publisherId + "/" + APIurls.articles,
			data
		),
	getSupportedCurrencies: async () => await apiService.get(APIurls.currencies),
};
