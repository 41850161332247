import React, { useState } from "react";
import {
  AddButton,
  SaveBtn,
  SoldDate,
  TopHeading,
  Wrapper,
} from "../../../../common/index.style";
import { Col, Form, Input, InputNumber, Row } from "antd";
import { MarkingArea } from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPublisher, resetPublisher } from "../../../../redux/publisherSlice";
import { useEffect } from "react";
import {
  addSettlement,
  setSettlement,
} from "../../../../redux/settlementSlice";
import { TbCurrencyDollar } from "react-icons/tb";
import { handleMinus, handleSettlementMinus } from "../../../../utils/helper";
import UserFormList from "./userFormList";

const Settle = () => {
  const [confirm, setConfirm] = useState(false);
  const [settle, setSettle] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();
  const store = useSelector((store) => store);
  
  useEffect(() => {
    if (id) {
      dispatch(getPublisher({ publisherId: id }));
    }

    return () => {
      dispatch(resetPublisher());
    };
  }, [id]);

  const { publisher, loading: ploading } = store?.publisher;
  const { settlement, loading } = store?.settlement;

  const onSubmitSettlement = (values) => {
    dispatch(setSettlement({ ...values }));
    setSettle(true);
  };

  return (
    <Wrapper>
      <TopHeading>
        {ploading ? "Loading..." : "Settle" + publisher?.name}
      </TopHeading>
      <Form onFinish={onSubmitSettlement} layout="vertical">
        <Row gutter={16}>
          {confirm ? (
            <Col md={{ span: 20 }}>
              <Row style={{ marginTop: "2em" }}>
                <Col span={6}></Col>
                <Col span={18}>
                  <MarkingArea>
                    <h5>
                      <em>${settlement?.settledAmount}</em> settlement saved for{" "}
                      {publisher?.name}
                    </h5>
                    <p>please make sure this payment has occurred in Stripe!</p>
                  </MarkingArea>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col md={{ span: 20 }}>
              <Row>
                <Col md={{ span: 8 }}>
                  <SoldDate>
                    $
                    {publisher?.sales > 0
                      ? publisher?.sales?.toFixed(2)
                      : publisher?.sales}{" "}
                    sold to date
                  </SoldDate>
                </Col>
                <Col md={{ span: 8 }}>
                  <SoldDate>
                    {publisher?.settled > 0
                      ? handleSettlementMinus(publisher?.settled)
                      : handleSettlementMinus(publisher?.settled ?? 0)}{" "}
                    settled to date
                  </SoldDate>
                </Col>
                <Col md={{ span: 8 }}>
                  <SoldDate>
                    {publisher?.balance
                      ? handleMinus(publisher?.balance)
                      : "$" + 0}{" "}
                    owed to publisher
                  </SoldDate>
                </Col>
                {settle ? (
                  <Col md={{ span: 24 }}>
                    <MarkingArea>
                      <h5>
                        Marking $<em>{settlement?.settledAmount}</em> as settled
                        for {publisher?.name}. Please confirm
                      </h5>
                      <p>
                        You will now owe ${" "}
                        {publisher?.balance - settlement?.settledAmount} , $
                        {settlement?.settledAmount} being settled to{" "}
                        {publisher?.name}
                      </p>
                    </MarkingArea>
                  </Col>
                ) : (
                  <Col md={{ span: 24 }}>
                    <SoldDate>
                      <Form.Item
                        name="settledAmount"
                        label="Enter settled amount in Stripe [##]"
                        rules={[{ required: true, message: "required" }]}
                      >
                        <InputNumber prefix={<TbCurrencyDollar />} />
                      </Form.Item>
                      <Form.Item
                        name="transactionId"
                        label="Transaction ID #"
                        rules={[{ required: true, message: "required" }]}
                      >
                        <Input />
                      </Form.Item>
                    </SoldDate>
                    <UserFormList
                      list={publisher.paidUserTransactionInformation}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          )}
          <Col md={{ span: 4 }}>
            {settle ? (
              <>
                {confirm ? (
                  <AddButton to="/settlement">Back to Home</AddButton>
                ) : (
                  <SaveBtn
                    type="button"
                    onClick={() => {
                      dispatch(
                        addSettlement({
                          settlementData: {
                            amount: settlement?.settledAmount,
                            publisherId: id,
                            transactionId: settlement?.transactionId,
                            paidHistory: settlement?.paidHistory,
                          },
                          setConfirm,
                        })
                      );
                    }}
                  >
                    {loading ? "loading..." : "Confirm"}
                  </SaveBtn>
                )}
              </>
            ) : (
              <SaveBtn type="submit">Settle</SaveBtn>
            )}
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
};

export default Settle;
