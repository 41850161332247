import React, { useEffect } from "react";
import {
  AddButton,
  DownloadButton,
  Filter,
  TopHeading,
  Wrapper,
} from "../../../common/index.style";
import TransactionDataTable from "../../../components/table/TransactionDataTable";
import { DataContent } from "../publishers/index.style";
import { Dropdown, Input } from "antd";
import { FilterArea } from "./index.style";
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransactions } from "../../../redux/transactionSlice";
import { CSVLink } from "react-csv";
import { csvTransactionData } from "../../../utils/helper";

const Transactions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllTransactions());
  }, []);

  const { allTransactions, loading } = useSelector(
    (state) => state.transaction
  );

  const data = csvTransactionData(allTransactions ?? []);

  return (
    <Wrapper>
      <TopHeading>Transactions</TopHeading>
      <Filter>
        <div></div>
        <CSVLink filename='transactions' data={data}>
          <DownloadButton>Download</DownloadButton>
        </CSVLink>
      </Filter>
      <DataContent>
        <TransactionDataTable
          loading={loading}
          transactions={allTransactions}
        />
      </DataContent>
    </Wrapper>
  );
};

export default Transactions;
