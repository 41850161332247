import React, { useEffect } from "react";
import {
  AddButton,
  DownloadButton,
  TopHeading,
  Wrapper,
} from "../../../common/index.style";
import PublisherDataTable from "../../../components/table/PublisherDataTable";
import { DataContent } from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import { getAllPublishers } from "../../../redux/publisherSlice";
import { CSVLink } from "react-csv";
import { csvPublisherData } from "../../../utils/helper";

const Publishers = () => {
  const dispatch = useDispatch();
  const store = useSelector((store) => store);

  useEffect(() => {
    dispatch(getAllPublishers());
  }, []);

  const { allPublisers, loading } = store?.publisher;

  const data = csvPublisherData(allPublisers ?? []);

  return (
    <Wrapper>
      <TopHeading>Publishers</TopHeading>
      <div style={{ textAlign: "right", marginBottom: "1em" }}>
        <div></div>
        <div>
          <AddButton to='/publisher/add'>Add Publisher</AddButton>
          <CSVLink filename='publishers' data={data}>
            <DownloadButton style={{ marginLeft: "1em" }}>
              Download
            </DownloadButton>
          </CSVLink>
        </div>
      </div>
      <DataContent>
        <PublisherDataTable publishers={allPublisers} loading={loading} />
      </DataContent>
    </Wrapper>
  );
};

export default Publishers;
