import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Wrapper, Container, Content, DataContent } from "./index.style";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { navLinks } from "./links";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../../redux/authSlice";

const AdminLayout = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUserProfile(navigate));
		const authUser = JSON.parse(localStorage.getItem("authUser"));
		if (!authUser) {
			navigate("/login");
		}
	}, [navigate]);

	return (
		<Wrapper>
			<Content>
				<Sidebar data={navLinks} />
				<Container>
					<Header />
					<DataContent>
						<Outlet />
					</DataContent>
				</Container>
			</Content>
		</Wrapper>
	);
};

export default AdminLayout;
