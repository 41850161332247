import auth from "./authSlice";
import publisher from "./publisherSlice";
import settlement from "./settlementSlice";
import transaction from "./transactionSlice";
import currency from "./currencySlice";
const rootReducer = {
  auth,
  publisher,
  settlement,
  transaction,
  currency,
};

export default rootReducer;
