import React, { useEffect } from "react";
import { AddButton, TopHeading, Wrapper } from "../../../../common/index.style";
import { Col, Row, message } from "antd";
import { CodeContainer, MarkingArea } from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import { getPublisher, resetPublisher } from "../../../../redux/publisherSlice";
import { useParams } from "react-router-dom";
import { copyToClipboard } from "../../../../utils/helper";

const ViewPublisher = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const store = useSelector((store) => store);
  useEffect(() => {
    if (id) {
      dispatch(getPublisher({ publisherId: id }));
    }

    return () => {
      dispatch(resetPublisher());
    };
  }, [id, dispatch]);

  const { publisher, loading } = store?.publisher;

  return (
    <Wrapper>
      {contextHolder}
      <TopHeading>View Publisher</TopHeading>
      <Row gutter={16}>
        <Col md={{ span: 16 }}>
          <Row>
            <Col span={24}>
              <MarkingArea>
                <h5>
                  {loading
                    ? "loading..."
                    : `Provide this code to ${publisher?.name}`}
                </h5>
              </MarkingArea>
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 8 }} style={{ textAlign: "right" }}>
          <AddButton
            style={{ marginLeft: "1.7em", marginTop: "2em" }}
            to='/publisher'>
            Back to Home
          </AddButton>
        </Col>

        {publisher?.stripe?.account_url !== null && (
          <Col md={{ span: 24 }}>
            <CodeContainer>
              <div className='codeHeader'>
                <span>Stripe Onboarding Link:</span>
              </div>

              <pre className='installationCode'>
                {loading ? "loading..." : publisher?.stripe?.account_url}
              </pre>
            </CodeContainer>
          </Col>
        )}

        <Col md={{ span: 24 }}>
          <CodeContainer>
            <div className='codeHeader'>
              <span>Default Code:</span>
              <div
                onClick={() =>
                  copyToClipboard(publisher?.code?.[0] ?? null, messageApi)
                }
                className='copyCode'>
                Copy Code
              </div>
            </div>

            <pre className='installationCode'>
              {loading
                ? "loading..."
                : publisher?.code?.[0]?.split(" ")?.map((item) => {
                    return item + "\n";
                  }) ?? null}
            </pre>
          </CodeContainer>
        </Col>

        <Col md={{ span: 24 }}>
          <CodeContainer>
            <div className='codeHeader'>
              <span>Custom Pricing Code:</span>
              <div
                onClick={() =>
                  copyToClipboard(publisher?.code?.[1] ?? null, messageApi)
                }
                className='copyCode'>
                Copy Code
              </div>
            </div>

            <pre className='installationCode'>
              {loading
                ? "loading..."
                : publisher?.code?.[1]?.split(" ")?.map((item) => {
                    return item + "\n";
                  }) ?? null}
            </pre>
          </CodeContainer>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ViewPublisher;
