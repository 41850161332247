export const navLinks = [
	{
		title: "Home",
		link: "/",
	},
	{
		title: "Publisher",
		link: "/publisher",
	},
	{
		title: "Add publisher",
		link: "/publisher/add",
	},
	{
		title: "Transactions",
		link: "/transaction",
	},
	{
		title: "Settlements",
		link: "/settlement",
	},
];
