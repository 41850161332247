import React, { useEffect } from "react";
import { SaveBtn, TopHeading, Wrapper } from "../../../common/index.style";
import { CustomContainer } from "./index.style";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Row,
  ColorPicker,
  Select,
  Divider,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  convertColorObjectToHex,
  hsvToHexWithAlpha,
  textSizeTags,
} from "../../../utils/helper";
import {
  editPublisher,
  getPublisher,
  resetPublisher,
} from "../../../redux/publisherSlice";
import { AiOutlinePercentage } from "react-icons/ai";
import { TbCurrencyDollar, TbFold } from "react-icons/tb";
import lookup from "country-code-lookup";
import { useMemo } from "react";
import { getSupportedCurrencies } from "../../../redux/currencySlice";
import { TEXTS } from "../../../utils/text";

const EditPublishers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const publisherId = params?.id;

  const store = useSelector((store) => store);
  const { loading: isSupportedCurrenciesLoading, supportedCurrencies } =
    useSelector((store) => store.currency);

  useEffect(() => {
    dispatch(getSupportedCurrencies());
  }, []);

  useEffect(() => {
    dispatch(getPublisher({ publisherId }));

    return () => {
      dispatch(resetPublisher());
    };
  }, [publisherId, dispatch]);

  const { publisher, loading } = store?.publisher;

  useEffect(() => {
    if (publisher) {
      form.setFieldsValue({
        publisherName: publisher?.name,
        firstName: publisher?.user?.firstName,
        lastName: publisher?.user?.lastName,
        website: publisher?.website,
        email: publisher?.email,
        country: publisher?.country,
        articlePrice: publisher?.articlePrice,
        abTest: publisher?.abTest ? "Yes" : "No",
        active: publisher?.active ? "Yes" : "No",
        apiKeyName: publisher?.apiKey ? "Yes" : "No",
        checkoutemail: publisher?.checkoutemail ? "Yes" : "No",
        revShare: publisher?.revShare1?.percentage,
        miniPrice: publisher?.revShare1?.minPrice,
        revShare2: publisher?.revShare2?.percentage,
        lowPrice2: publisher?.revShare2?.minPrice,
        highPrice2: publisher?.revShare2?.maxPrice,
        revShare3: publisher?.revShare3?.percentage,
        currency: publisher?.stripe?.currency,
        lowPrice3: publisher?.revShare3?.minPrice,
        highPrice3: publisher?.revShare3?.maxPrice,
        paywallPadding: publisher?.style?.paywallPadding,
        paywallButtonClass: publisher?.style?.paywallButtonClass,
        paywallButtonColor: publisher?.style?.paywallButtonColor,
        paywallButtonTextColor: publisher?.style?.paywallButtonTextColor,
        paywallButtonTextSize: publisher?.style?.paywallButtonTextSize,
        paywallContainerClass: publisher?.style?.paywallContainerClass,
        paddingTop: publisher?.style?.paddingTop,
        paddingBottom: publisher?.style?.paddingBottom,
        paywallPromptTextClass: publisher?.style?.paywallPromptTextClass,
        paywallPromptTextColor: publisher?.style?.paywallPromptTextColor,
        paywallPromptTextSize: publisher?.style?.paywallPromptTextSize,
        paywallButtonTag: publisher?.style?.paywallButtonTag,
        isButtonNext: publisher?.style?.isButtonNext,
        paywallButtonWrapperClass: publisher?.style?.paywallButtonWrapperClass,
        ghContentClass: publisher?.style?.ghContentClass,
        paymentButtonColor: publisher?.primaryColor,
        textColor: publisher?.secondaryColor,
        viewEvent: publisher?.viewEventActive ? "Yes" : "No",
        actaPromptText: publisher?.actaPromptText,
        actaButtonText: publisher?.actaButtonText,
        apiKey: publisher?.ghostAPI?.apiKey,
        apiURL: publisher?.ghostAPI?.apiURL,
      });
    }
  }, [publisher, dispatch, form]);

  const currencies = useMemo(() => {
    const currencyOptions = supportedCurrencies.map((currency) => {
      return {
        label: currency.toUpperCase(),
        value: currency.toLowerCase(),
      };
    });
    return currencyOptions;
  }, [supportedCurrencies]);

  const onFinish = (values) => {
    const paymentButtonColor =
      values?.paymentButtonColor?.metaColor?.originalInput;
    const paymentTextColor = values?.textColor?.metaColor?.originalInput;
    const actaPromptText =
      values?.actaPromptText === undefined ||
      values?.actaPromptText === TEXTS.EMPTY_TEXT
        ? TEXTS.DEFAULT_ACTA_PROMPT_TEXT
        : values?.actaPromptText;
    const actaButtonText =
      values?.actaButtonText === undefined ||
      values?.actaButtonText === TEXTS.EMPTY_TEXT
        ? TEXTS.DEFAULT_ACTA_BUTTON_TEXT
        : values?.actaButtonText;

    const publisherData = {
      name: values?.publisherName,
      email: values?.email,
      website: values?.website,
      userFirstName: values?.firstName,
      userLastName: values?.lastName,
      country: values?.country,
      articlePrice: values?.articlePrice,
      viewEventActive: values?.viewEvent === "Yes" ? true : false,
      revShare1: {
        percentage: values?.revShare,
        minPrice: values?.miniPrice,
      },

      revShare2: {
        percentage: values?.revShare2 ?? null,
        minPrice: values?.lowPrice2 ?? null,
        maxPrice: values?.highPrice2 ?? null,
      },
      revShare3: {
        percentage: values?.revShare3 ?? null,
        minPrice: values?.lowPrice3 ?? null,
        maxPrice: values?.highPrice3 ?? null,
      },
      stripe: {
        currency: values?.currency,
      },
      // abTest: values?.abTest === "Yes" ? true : false,
      active: values?.active === "Yes" ? true : false,

      checkoutemail: values?.checkoutemail === "Yes" ? true : false,
      ghostAPI: {
        apiKey: values?.apiKey,
        apiURL: values?.apiURL,
      },
      style: {
        paywallButtonClass: values?.paywallButtonClass,
        isButtonNext: values?.isButtonNext,
        paywallButtonColor:
          convertColorObjectToHex(
            values?.paywallButtonColor,
            publisher?.style?.paywallButtonColor
          ) ?? null,
        paywallButtonTextColor:
          convertColorObjectToHex(
            values?.paywallButtonTextColor,
            publisher?.style?.paywallButtonTextColor
          ) ?? null,
        paywallButtonTextSize: values?.paywallButtonTextSize ?? null,
        paywallContainerClass: values?.paywallContainerClass,
        paywallPadding: values?.paywallPadding,
        paywallPromptTextClass: values?.paywallPromptTextClass || null,
        paywallPromptTextColor:
          convertColorObjectToHex(
            values?.paywallPromptTextColor,
            publisher?.style?.paywallPromptTextColor
          ) ?? null,
        paywallPromptTextSize: values?.paywallPromptTextSize ?? null,
        paddingTop: values?.paddingTop ?? null,
        paddingBottom: values?.paddingBottom ?? null,
        paywallButtonTag: values?.paywallButtonTag,
        paywallButtonWrapperClass: values?.paywallButtonWrapperClass || null,
        ghContentClass: values?.ghContentClass || null,
      },

      primaryColor: paymentButtonColor
        ? typeof paymentButtonColor === "object" &&
          Object.keys(paymentButtonColor)?.length > 0
          ? hsvToHexWithAlpha(
              paymentButtonColor?.h,
              paymentButtonColor?.s,
              paymentButtonColor?.v,
              paymentButtonColor?.a
            )
          : "#" + paymentButtonColor
        : publisher?.primaryColor,
      secondaryColor: paymentTextColor
        ? typeof paymentTextColor === "object" &&
          Object.keys(paymentTextColor)?.length > 0
          ? hsvToHexWithAlpha(
              paymentTextColor?.h,
              paymentTextColor?.s,
              paymentTextColor?.v,
              paymentTextColor?.a
            )
          : "#" + paymentTextColor
        : publisher?.secondaryColor,
      actaPromptText: actaPromptText,
      actaButtonText: actaButtonText,
    };
    dispatch(editPublisher({ publisherId, publisherData, navigate }));
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const countries = useMemo(() => {
    const countries = lookup.countries;
    const countryOptions = [];
    countries.forEach((country) => {
      countryOptions.push({
        label: country.country,
        value: country.iso2,
      });
    });
    return countryOptions;
  }, []);

  const paywallButtonTags = [
    {
      label: "a",
      value: "a",
    },
    {
      label: "button",
      value: "button",
    },
    {
      label: "div",
      value: "div",
    },
  ];

  return (
    <Wrapper>
      <CustomContainer>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="publisherForm">
            <TopHeading>Edit Publishers</TopHeading>
            <div>
              <SaveBtn
                htmlType="button"
                // onClick={() => navigate("/add_publisher/view")}
              >
                {loading ? "loading..." : "Save"}
              </SaveBtn>
            </div>
          </div>
          <Row justify="space-between" gutter={16} className="userInputs">
            <Col>
              <Form.Item
                name="publisherName"
                label="Publisher Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="website" label="Website">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { type: "email", message: "email is not valid" },
                  { required: true, message: "required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 4 }}>
              <Form.Item
                name="viewEvent"
                label="Page View Event Active?"
                rules={[{ required: true, message: "required" }]}
              >
                <Radio.Group>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  showSearch
                  // placeholder='Select a country'
                  optionFilterProp="children"
                  onChange={(v) => console.log(v)}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  options={countries}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  loading={isSupportedCurrenciesLoading}
                  options={currencies}
                />
              </Form.Item>
            </Col>

            <Col lg={{ span: 3 }}>
              <Form.Item
                name="active"
                label="Active?"
                rules={[{ required: true, message: "required" }]}
              >
                <Radio.Group>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col lg={{ span: 3 }}>
              <Form.Item
                name="checkoutemail"
                label="Checkout Email?"
                rules={[{ required: true, message: "required" }]}
              >
                <Radio.Group>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {/* <Col lg={{ span: 4 }}>
              <Form.Item
                name='abTest'
                label='A/B Test'
                rules={[{ required: true, message: "required" }]}>
                <Radio.Group>
                  <Radio value='Yes'>Yes</Radio>
                  <Radio value='No'>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={26}>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="articlePrice"
                label="Default Article price"
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="revShare"
                label="Rev share "
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber prefix={<AiOutlinePercentage />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="miniPrice"
                label="Minimum price"
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col lg={{ span: 6 }}>
              <Form.Item name="revShare2" label="Rev share 2 (optional)">
                <InputNumber prefix={<AiOutlinePercentage />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="lowPrice2" label="Low price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="highPrice2" label="High price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="revShare3" label="Rev share 3 (optional)">
                <InputNumber prefix={<AiOutlinePercentage />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="lowPrice3" label="Low price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="highPrice3" label="High price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>

            <Col lg={{ span: 12 }}>
              <Form.Item
                name="apiKey"
                label="API Key"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={{ span: 9 }}>
              <Form.Item
                name="apiURL"
                label="API URL"
                rules={[
                  { required: true, message: "required" },
                  {
                    type: "url",
                    message: "Please enter a valid URL",
                  },
                  {
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* <Col lg={{ span: 9 }}>
              <Form.Item label="Stripe / bank account">
                <Input />
              </Form.Item>
            </Col> */}
          </Row>
          <Divider />
          <h2>Styles</h2>
          <Row gutter={28}>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallContainerClass"
                label="Paywall container class"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonClass"
                label="Paywall button class"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallPromptTextClass"
                label="Paywall prompt text class"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallPromptTextSize"
                label="Paywall prompt text size"
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={textSizeTags}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={28} style={{ marginTop: 10 }}>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonWrapperClass"
                label="Paywall button wrapper class"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }}>
              <Form.Item
                name="paywallButtonTag"
                label="Paywall button tag"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={paywallButtonTags}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonTextSize"
                label="Paywall button text size"
              >
                <InputNumber min={1} suffix={<p>px</p>} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="paywallPadding" label="Paywall Padding">
                <InputNumber min={1} suffix={<p>px</p>} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={28} style={{ marginTop: 10 }}>
            <Col lg={{ span: 6 }}>
              <Form.Item name="ghContentClass" label="Gh content class">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="actaPromptText" label="Acta prompt text">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="actaButtonText" label="Acta button text">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 4 }}>
              <Form.Item
                name="isButtonNext"
                valuePropName="checked"
                label="Button alternative position"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={28} style={{ marginTop: 10 }}>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonTextColor"
                label="Paywall button text color"
              >
                <ColorPicker allowClear showText defaultValue={null} to />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="paywallButtonColor" label="Paywall button color">
                <ColorPicker allowClear showText defaultValue={null} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallPromptTextColor"
                label="Paywall prompt text color"
              >
                <ColorPicker allowClear defaultValue={null} showText />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="paymentButtonColor" label="Payment button color">
                <ColorPicker showText defaultValue={null} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="textColor" label="Outline / text color">
                <ColorPicker showText defaultValue={null} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="paddingTop" label="Title PaddingTop">
                <InputNumber suffix={<p>px</p>} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="paddingBottom" label="Title Paddingbottom">
                <InputNumber suffix={<p>px</p>} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomContainer>
    </Wrapper>
  );
};

export default EditPublishers;
