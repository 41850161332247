import styled from "styled-components";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  a {
    padding: 0.5em 1.5em;
    margin: 0.2em 0;
    border-radius: 4px;
    font-size: 1.3em;
    font-weight: 700;
    color: #d9d9d9;
    span {
      display: block;
    }
    &:hover {
      background: #d9d9d9;
      border-radius: 4px;
      color: #434343;
    }
  }
  .active {
    background: #d9d9d9;
    border-radius: 4px;
    color: #434343;
  }
  @media (max-width: 1300px) {
    & {
      a {
        padding: 0.5em 1em;
        margin: 0.5em 0;
      }
    }
  }
`;
