import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

// add publisher
export const addpublisher = createAsyncThunk(
  "publisher/addpublisher",
  async ({ publisherData, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addpublisher(publisherData);
      if (response.status === 201) {
        navigate("/publisher");
        toast.success("Publisher addd successfully!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });

        return response?.data?.RESPONSE;
      }
    } catch (error) {
      toast.error(error?.response?.data?.ERROR?.message, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// edit publisher
export const editPublisher = createAsyncThunk(
  "publisher/editPublisher",
  async (
    { publisherId, publisherData, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.editPublisher(
        publisherId,
        publisherData
      );
      if (response.status === 200) {
        navigate("/publisher");
        toast.success("Publisher updated successfully!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      toast.error(error?.response?.data?.ERROR?.message, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// get all publisher
export const getAllPublishers = createAsyncThunk(
  "publisher/getAllPublishers",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPublishers();
      if (response.status === 200) {
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  }
);

// get single publisher
export const getPublisher = createAsyncThunk(
  "publisher/getPublisher",
  async ({ publisherId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPublisher(publisherId);
      if (response.status === 200) {
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  }
);

// delete publisher
export const deletePublisher = createAsyncThunk(
  "publisher/deletePublisher",
  async ({ publisherId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deletePublisher(publisherId);
      if (response.status === 200) {
        dispatch(getAllPublishers());
        toast.success("Publisher deleted successfully!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });

        return response?.data?.RESPONSE;
      }
    } catch (error) {
      toast.error(error?.response?.data?.ERROR?.message, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

const initialState = {
  loading: false,
  publisher: {},
  allPublisers: [],
  downloadPublishersData: [],
};

const publisherSlice = createSlice({
  name: "publisher",
  initialState,
  reducers: {
    resetPublisher(state) {
      state.publisher = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addpublisher.pending, (state) => {
        state.loading = true;
      })
      .addCase(addpublisher.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addpublisher.rejected, (state) => {
        state.loading = false;
      })

      .addCase(editPublisher.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPublisher.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editPublisher.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAllPublishers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPublishers.fulfilled, (state, action) => {
        state.loading = false;
        state.allPublisers = action.payload;
      })
      .addCase(getAllPublishers.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPublisher.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPublisher.fulfilled, (state, action) => {
        state.loading = false;
        state.publisher = action.payload;
      })
      .addCase(getPublisher.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default publisherSlice.reducer;
export const { resetPublisher } = publisherSlice.actions;
