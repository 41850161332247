import { Form } from "antd";
import styled from "styled-components";

export const CustomContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(23deg, #cbbcfa, #ffffff);
  h6 {
    font-size: 1.5em;
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 1.5em;
  }
`;
export const FormContainer = styled(Form)`
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #000000;
  background: #f3f3f3;
  min-width: 500px;
  .ant-form-item-label {
    .ant-form-item-required {
      font-size: 1.2em;
      font-weight: 600 !important;
      &::before {
        display: none !important;
      }
    }
  }
  .ant-input-password,
  .ant-input {
    height: 35px;
    background: #ffffff;
    border: 1px solid rgb(0, 0, 0);
    font-size: 1.2em;
    font-weight: 700;
    &:hover {
      background: #ffffff;
      border: 1px solid rgb(0, 0, 0);
    }
    &::placeholder {
      color: #000000;
      font-weight: 700;
    }
  }
  .ant-form-item {
    margin-bottom: 0.8em;
  }
  .ant-input-affix-wrapper {
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-password {
    .ant-input {
      width: 100%;
      height: unset;
      background: #ffffff;
      border: none;
      font-size: 1em;
    }
  }
  .ant-btn {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    font-size: 1em;
    font-family: var(--font-catamaran);
    transition: all 200ms ease-in-out 0s;
    font-weight: 600 !important;
    background-color: #4209ee;
    span {
      color: rgb(255, 255, 255);
    }
    &:hover {
      background: rgb(53, 21, 150) !important;
    }
  }
`;
export const FormHeader = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  background: rgb(0, 0, 0);
  text-align: center;
  height: 50px;
  h1 {
    color: #ffffff;
    font-size: 1.2em;
    margin-bottom: 0;
  }
`;
export const FormBody = styled("div")`
  padding: 4em 1em 0;
`;
