import styled from "styled-components";

export const HeaderContainer = styled("div")`
  padding: 1em 3%;
  background: #434343;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 90px;
`;

export const NavLinks = styled("div")`
  a {
    display: inline-flex;
    align-items: center;
    color: #d9d9d9;
    font-size: 1.5em;
    font-weight: 700;
    &:last-child {
      margin-left: 2em;
      svg {
        margin-left: 0.5em;
      }
    }
  }
  .ant-dropdown-trigger {
    padding: 0.3em 1.7em;

    &:focus,
    &:focus-within,
    &:hover {
      background: #d9d9d9;
      color: #000000;
      padding: 0.5em 1.7em;
      border-radius: 5px;
    }
  }
`;
