import React from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { HeaderContainer, NavLinks } from "./index.style";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const items = [
		{
			label: (
				<div
					onClick={() => {
						dispatch(logout());
						navigate("/login");
					}}
				>
					Logout
				</div>
			),
			key: "1",
		},
		{
			label: <a href='/setting/change-password'>Change Password</a>,
			key: "2",
		},
	];
	return (
		<HeaderContainer>
			<NavLinks>
				<Dropdown
					menu={{
						items,
					}}
					trigger={["click"]}
				>
					<a onClick={(e) => e.preventDefault()}>
						Admin
						<AiFillCaretDown />
					</a>
				</Dropdown>
			</NavLinks>
		</HeaderContainer>
	);
};

export default Header;
