import React, { useEffect, useState } from "react";

import { Row, Form, Col, Input, Button, Spin } from "antd";
import { FormFields } from "./formFields";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const UserRowInput = ({ rowKey, name, ...restField }) => {
  const mapColumnRepeatItems = FormFields.map(
    ({ label, rules, field }, index) => (
      <Col md={{ span: 6 }} key={index}>
        <Form.Item
          {...restField}
          rules={rules}
          label={rowKey === 0 && label}
          name={[name, `${field}`]}
        >
          <Input />
        </Form.Item>
      </Col>
    )
  );
  return <>{mapColumnRepeatItems}</>;
};

const UserFormList = ({ list }) => {
  const [initialized, setInitialValue] = useState(false);

  useEffect(() => {
    if (typeof list === "object") {
      setInitialValue(true);
    }
  }, [list]);

  return (
    <>
      {initialized ? (
        <Form.List name="paidHistory" initialValue={list}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key, fieldKey, ...restField }) => (
                <Row gutter={8} key={key} justify="space-around" align="middle">
                  <UserRowInput rowKey={key} name={name} {...restField} />
                  <Col md={{ span: 3 }}>
                    {" "}
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ margin: "0 8px" }}
                    />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      ) : (
        <>
          <Spin tip="Loading" size="small">
            <div className="content" />
          </Spin>
        </>
      )}
    </>
  );
};

export default UserFormList;
