import { Table } from "antd";
import styled from "styled-components";

export const CustomTable = styled(Table)`
  .ant-table-content{
    height:700px;
    overflow-y:scroll!important;
  }
  .ant-table-pagination {
    display: none;
  }
  tbody,
  thead {
    tr {
      td,
      th {
        border: 1px solid #00000095 !important;
        font-size: 0.9em;
      }
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      background: #efefef;
      font-weight: 700;
      //   width: 100px;
      &:last-child {
        width: 130px;
        span {
          /* font-size: 0.8em; */
        }
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      &:last-child {
       span, a {
        cursor: pointer;
          color: #000000;
          font-weight: 700;
          font-size: 11px;
        }
      }
    }
}
@media (max-width:1440px) {
  & {
    .ant-table-cell {
      &:last-child {
        a {
          font-size: 0.9em;
        }
      }
    }
    
    tbody,
    thead {
      tr {
        td,
        th {
          font-size: 0.85em;
          padding: 1em;
        }
    }
  }
}
  @media (max-width: 1300px) {
    width: 1200px;
    overflow-x: auto;
    tbody,
    thead {
      tr {
        td,
        th {
          /* font-size: 0.8em; */
          padding: 1em 0.5em;
        }
      }
    }
  }
`;
