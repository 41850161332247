import {
	passwordConfirmValidator,
	passwordLengthValidator,
} from "./passwordValidator";

export const FormItemArray = [
	{
		label: "Old Passowrd",
		name: "oldPassword",
		message: "Please input your old password!",
	},
	{
		label: "New Passowrd",
		name: "newPassword",
		message: "Please input your new password!",
		validator: () => passwordLengthValidator(),
	},
	{
		label: "Confirm Passowrd",
		name: "confirmPassword",
		message: "Please input your confirm password!",
		validator: (form) => passwordConfirmValidator(form),
	},
];
