import styled from "styled-components";

export const MarkingArea = styled("div")`
  // max-width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fce5cd;
  height: 130px;
  padding: 1em 2em;
  h5 {
    font-size: 1.5em;
    font-weight: 700;
  }
  p {
    color: #000000;
    font-weight: 500;
    font-size: 1.3em;
    font-style: italic;
    margin-bottom: 0;
  }
  @media (max-width: 1400px) {
    & {
      h5 {
        font-size: 1.2em;
      }
      p {
        font-size: 1.1em;
      }
    }
  }
`;
