import moment from "moment";

// hsva to hex
export function hsvToHexWithAlpha(h, s, v, a) {
  var f = (n, k = (n + h / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);

  const red = Math.round(f(5) * 255);
  const green = Math.round(f(3) * 255);
  const blue = Math.round(f(1) * 255);
  const alpha = Math.round(a * 255); // Convert alpha to a value between 0 and 255

  return `#${red.toString(16).padStart(2, "0")}${green
    .toString(16)
    .padStart(2, "0")}${blue.toString(16).padStart(2, "0")}${alpha
    .toString(16)
    .padStart(2, "0")}`;
}

export function handleMinus(number) {
  if (number < 0) {
    return "-$" + (-1 * number).toFixed(2);
  } else {
    return "$" + number.toFixed(2);
  }
}

export function handleSettlementMinus(number) {
  if (number < 0) {
    return "-$" + (-1 * number).toFixed(3);
  } else {
    return "$" + number.toFixed(3);
  }
}

//copy to clipboard
export function copyToClipboard(text, messageApi) {
  // Create a temporary textarea element
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed"; // Make it invisible but still accessible
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();
  textarea.setSelectionRange(0, text.length); // For mobile devices

  // Copy the selected text to the clipboard
  document.execCommand("copy");

  // Clean up: remove the textarea from the DOM
  document.body.removeChild(textarea);
  messageApi.open({
    type: "success",
    content: "Copied to clipboard!",
    duration: 2,
  });
}

// csv
export const csvPublisherData = (publishers) => {
  const headers = [
    [
      "#",
      "Created",
      "Publisher",
      "Website",
      "Email",
      "Rev Share",
      "Rev Share 2",
      "Rev Share 3",
      "Article Price",
      "Min Price",
      "Sold to date",
      "Settled to date",
      "Owed to publisher",
      "Active",
    ],
  ];
  const rows = publishers?.map((publisher, index) => {
    let rev2 = "NA";
    let rev3 = "NA";
    if (publisher.revShare2.enabled) {
      rev2 = `${publisher.revShare2.percentage}%`;
      if (publisher.revShare2.minPrice && publisher.revShare2.maxPrice) {
        rev2 += ` ($${publisher.revShare2.minPrice} - $${publisher.revShare2.maxPrice})`;
      } else if (
        publisher.revShare2.minPrice &&
        !publisher.revShare2.maxPrice
      ) {
        rev2 += ` (> $${publisher.revShare2.minPrice})`;
      } else if (
        !publisher.revShare2.minPrice &&
        publisher.revShare2.maxPrice
      ) {
        rev2 += ` (< $${publisher.revShare2.maxPrice})`;
      }
    }
    if (publisher.revShare3.enabled) {
      rev3 = `${publisher.revShare3.percentage}%`;
      if (publisher.revShare3.minPrice && publisher.revShare3.maxPrice) {
        rev3 += ` ($${publisher.revShare3.minPrice} - $${publisher.revShare3.maxPrice})`;
      } else if (
        publisher.revShare3.minPrice &&
        !publisher.revShare3.maxPrice
      ) {
        rev3 += ` (> $${publisher.revShare3.minPrice})`;
      } else if (
        !publisher.revShare3.minPrice &&
        publisher.revShare3.maxPrice
      ) {
        rev3 += ` (< $${publisher.revShare3.maxPrice})`;
      }
    }
    return [
      (index + 1).toString(),
      moment(publisher.createdAt).format("l"),
      publisher.name,
      publisher.website,
      publisher.email,
      `${publisher.revShare1.percentage}%`,
      rev2,
      rev3,
      `$${publisher.articlePrice}`,
      publisher.revShare1.minPrice ? `$${publisher.revShare1.minPrice}` : "NA",
      `$ ${publisher.sales}`,
      `$ ${publisher.settled}`,
      `$ ${publisher.balance}`,
      String(publisher.active ? "Yes" : "No"),
    ];
  });

  const csvData = headers.concat(rows);
  return csvData;
};

export const csvSettlementData = (settlements) => {
  const headers = [
    [
      "#",
      "Created",
      "Publisher",
      "Website",
      "Settled",
      "Last settlement date",
      "Days since last settlement",
    ],
  ];
  const rows = settlements?.map((settlement, index) => {
    return [
      (index + 1).toString(),
      moment(settlement.createdAt).format("l"),
      settlement.publisher?.name,
      settlement.publisher?.website,
      "$" + settlement.amount,
      moment(settlement.lastSettlement).format("l"),
      moment().diff(moment(settlement?.lastSettlement), "days"),
    ];
  });

  const csvData = headers.concat(rows);
  return csvData;
};

export const csvTransactionData = (transactions) => {
  const headers = [
    [
      "#",
      "Created",
      "User",
      "User Email",
      "Publisher",
      "Article URL",
      "Transacted",
      "Processing Fee",
      "Revenue Share",
      "Net Revenue",
    ],
  ];
  const rows = transactions?.map((transaction, index) => {
    return [
      (index + 1).toString(),
      moment(transaction.createdAt).format("l"),
      transaction?.user?.name,
      transaction.user?.email,
      transaction.publisher?.name,
      transaction.articleURL,
      "$" + transaction.amount,
      "$" + transaction.platformFee,
      transaction.revShare + "%",
      transaction.netRev + "%",
    ];
  });

  const csvData = headers.concat(rows);
  return csvData;
};

export const textSizeTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p"].map(
  (tag) => {
    const firstLetter = tag.slice(0, 1);
    const tagLabel =
      firstLetter === "h"
        ? `Heading ${tag.slice(1)}`
        : firstLetter === "p"
        ? "Normal text"
        : tag;
    return {
      label: tagLabel,
      value: tag,
    };
  }
);

export const convertColorObjectToHex = (colorObject, defaultValue) => {
  const colorOriginalInput = colorObject?.metaColor?.originalInput;
  if (colorOriginalInput) {
    if (
      typeof colorOriginalInput === "object" &&
      Object.keys(colorOriginalInput)?.length > 0
    ) {
      const hexWithAlpha = hsvToHexWithAlpha(
        colorOriginalInput?.h,
        colorOriginalInput?.s,
        colorOriginalInput?.v,
        colorOriginalInput?.a
      );
      return hexWithAlpha.slice(-2) === "00" ? null : hexWithAlpha;
    } else {
      return "#" + colorOriginalInput;
    }
  } else {
    return defaultValue;
  }
};
