import React, { useRef, useState } from "react";
import { CustomTable } from "./index.style";
import moment from "moment/moment";
import { Button, Input, Space, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { deleteSettlement } from "../../redux/settlementSlice";
import { useDispatch } from "react-redux";

const SettlementsDataTable = ({ settlements, loading }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (sid) => {
    modal.confirm({
      title: "Are you sure you want to delete this settlement?",
      onOk: async () => {
        await dispatch(deleteSettlement({ settlementId: sid }));
      },
    });
  };

  const dataSource = [
    // {
    //   key: "1",
    //   name: "6/1/23",
    //   publisher: "Abc",
    //   website: "abc.com ",
    //   // email: "abc@email.com",
    //   // share: "$1,000,00",
    //   share2: "$900,000",
    //   share3: "$100,000 ",
    //   article: "6/7/23 ",
    //   active: "15",
    // },
  ];

  settlements?.map((settlement, i) => {
    dataSource.push({
      key: i,
      created: moment(settlement?.createdAt).format("l"),
      publisher: settlement?.publisher?.name,
      website: settlement?.publisher?.website,
      settled: "$" + settlement?.amount,
      lastSettlementDate: moment(settlement?.lastSettlement).format("l"),
      daysSinceLastSettlement: moment()
        .startOf("day")
        .diff(moment(settlement?.lastSettlement).startOf("day"), "days"),
      action: (
        <div>
          <span
            className="actionBtn"
            onClick={() => handleDelete(settlement?.id)}
          >
            [delete]
          </span>
        </div>
      ),
    });
  });

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      ...getColumnSearchProps("created"),
    },
    {
      title: "Publisher",
      dataIndex: "publisher",
      key: "publisher",
      ...getColumnSearchProps("publisher"),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      ...getColumnSearchProps("website"),
      width: 200,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },
    // {
    //   title: "$ sold to date",
    //   dataIndex: "share",
    //   key: "share",
    // },
    {
      title: "$ Settled",
      dataIndex: "settled",
      key: "settled",
      width: 150,

      ...getColumnSearchProps("settled"),
    },

    {
      title: "Last settlement date",
      dataIndex: "lastSettlementDate",
      key: "lastSettlementDate",
      ...getColumnSearchProps("lastSettlementDate"),
      width: 200,
    },
    {
      title: "Days since last settlement",
      dataIndex: "daysSinceLastSettlement",
      key: "daysSinceLastSettlement",
      width: 200,
      ...getColumnSearchProps("daysSinceLastSettlement"),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 200,
    },
    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (_, record) => (
    //     <>
    //       <div>
    //         <Link to="/settlement/settle">[settle]</Link>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      {contextHolder}
      <CustomTable
        loading={loading}
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </>
  );
};

export default SettlementsDataTable;
