import "./App.css";
// import "antd/dist/antd.min.css";
import AllRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";

const theme = {
	button: `rgb(201, 218, 248)`,
};

function App() {
	return (
		<div className='App'>
			<ConfigProvider>
				<ThemeProvider theme={theme}>
					<AllRoutes />
					<ToastContainer />
				</ThemeProvider>
			</ConfigProvider>
		</div>
	);
}

export default App;
