import styled from "styled-components";

export const Wrapper = styled("div")`
  background: #ffffff;
`;
export const Content = styled("div")`
  display: flex;
`;

export const DataContent = styled("div")`
  padding: 0 3em;
`;
export const Container = styled("div")`
  width: calc(100% - 270px);
  height: 100%;
  background: #ffffff;
  margin-left: 270px;
`;
