import React from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Container } from "./index.style";

const Navigation = (props) => {
  const data = props.data;
  const location = useLocation();

  return (
    <Container>
      {data.map((item, i) => (
        <Link
          className={location?.pathname === item.link ? "active" : ""}
          key={i}
          to={item.link}>
          <span>{item.title}</span>
        </Link>
      ))}
    </Container>
  );
};

export default Navigation;
