import { Link } from "react-router-dom";
import styled from "styled-components";

export const TabBtn = styled(Link)`
  max-width: 250px;
  padding: 0.3em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #434343;
  color: #ffffff;
  border-radius: 8px;
  margin-bottom: 3em;
  font-weight: 700;
  font-size: 1.4em;
  @media (max-width: 1500px) {
    & {
      font-size: 1.1em;
      margin-bottom: 2.5em;
    }
  }
`;
