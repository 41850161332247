import React from "react";
import { AddButton, TopHeading, Wrapper } from "../../../../common/index.style";
import { Col, Row } from "antd";
import { CodeContainer, MarkingArea } from "./index.style";

const DeleteModal = () => {
  return (
    <Wrapper>
      <TopHeading>Publisher</TopHeading>
      <Row gutter={16} align="middle">
        <Col span={24} style={{ marginBottom: "2em", textAlign: "right" }}>
          <AddButton style={{ marginLeft: "1.7em" }} to="/publisher">
            Confirm
          </AddButton>
        </Col>
        <Col md={{ span: 19 }}>
          <Row>
            <Col span={24}>
              <MarkingArea>
                <h5>Confirm deletion of</h5>
                <h5>Publisher ABC (abc.com)</h5>
              </MarkingArea>
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default DeleteModal;
