import React from "react";
import { TopHeading, Wrapper } from "../../../common/index.style";
import { Col, Row } from "antd";
import { TabBtn } from "./index.style";

const Home = () => {
  const links = [
    // {
    //   title: "General settings",
    //   address: "/setting",
    // },
    {
      title: "Admin Users",
      address: "#",
    },
    {
      title: "Publishers",
      address: "/publisher",
    },
    {
      title: "Add publisher",
      address: "/publisher/add",
    },
    {
      title: "Transactions",
      address: "/transaction",
    },
    {
      title: "Settlements",
      address: "/settlement",
    },
  ];
  return (
    <Wrapper>
      <TopHeading>Welcome to Admin Dashboard</TopHeading>
      <div>
        <Row gutter={16}>
          {links.map((item, i) => (
            <Col lg={{ span: 6 }} key={i}>
              <TabBtn to={item.address}>
                <span>{item.title}</span>
              </TabBtn>
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
};

export default Home;
