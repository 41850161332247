export const FormFields = [
  {
    field: "email",
    label: "Email",
  },
  {
    field: "user",
    label: "User",
  },
  {
    field: "article",
    label: "Article",
  },
];
