import React, { useEffect } from "react";
import { Button, Input } from "antd";
import {
  CustomContainer,
  FormBody,
  FormContainer,
  FormHeader,
} from "./index.style";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/authSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const navigate = useNavigate();

  const { loading } = store?.auth;

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      navigate("/");
    }
  }, [navigate]);

  const onFinish = (values) => {
    dispatch(login({ loginData: values, navigate }));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <CustomContainer>
      <div>
        <h6>Acta</h6>
        <p>Admin Dashboard</p>

        <FormContainer
          name='basic'
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          layout='vertical'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'>
          <FormHeader>
            <h1>Login</h1>
          </FormHeader>
          <FormBody>
            <FormContainer.Item
              label=''
              name='email'
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}>
              <Input placeholder='Email' />
            </FormContainer.Item>

            <FormContainer.Item
              label=''
              name='password'
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}>
              <Input.Password placeholder='Password' />
            </FormContainer.Item>

            <FormContainer.Item>
              <Button type='primary' htmlType='submit'>
                {loading ? "loading..." : "Login"}
              </Button>
            </FormContainer.Item>
          </FormBody>
        </FormContainer>
      </div>
    </CustomContainer>
  );
};

export default Login;
