import React, { useEffect } from "react";
import {
  AddButton,
  DownloadButton,
  Filter,
  TopHeading,
  Wrapper,
} from "../../../common/index.style";
import SettlementsDataTable from "../../../components/table/SettlementsDataTable";
import { DataContent } from "../publishers/index.style";
import { useDispatch, useSelector } from "react-redux";
import { getAllSettlements } from "../../../redux/settlementSlice";
import { CSVLink } from "react-csv";
import { csvSettlementData } from "../../../utils/helper";
const Settlements = () => {
  const dispatch = useDispatch();

  const store = useSelector((store) => store);
  useEffect(() => {
    dispatch(getAllSettlements());
  }, [dispatch]);

  const { allSettlements, loading } = store.settlement;

  const data = csvSettlementData(allSettlements ?? []);
  return (
    <Wrapper>
      <TopHeading>Settlements</TopHeading>
      <Filter>
        <div></div>

        <CSVLink filename='settlements' data={data}>
          <DownloadButton>Download</DownloadButton>
        </CSVLink>
      </Filter>
      <DataContent>
        <div style={{ maxWidth: "800px" }}>
          <SettlementsDataTable
            settlements={allSettlements}
            loading={loading}
          />
        </div>
      </DataContent>
    </Wrapper>
  );
};

export default Settlements;
