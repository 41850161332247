import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../screens/auth/login";
import Home from "../screens/admin/home";
import AdminLayout from "../layout/adminLayout";
import Publishers from "../screens/admin/publishers";
import AddPublishers from "../screens/admin/addPublishers";
import Transactions from "../screens/admin/Transactions";
import Settlements from "../screens/admin/Settlements";
import EditPublishers from "../screens/admin/editPublishers";
import Settle from "../screens/admin/Settlements/Settle";
import ViewPublisher from "../screens/admin/publishers/view";
import DeleteModal from "../screens/admin/publishers/deleteModal";
import ChangePassword from "../screens/admin/setting/changePassword";

const AllRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path='/login'
					element={<Login />}
				/>
				<Route element={<AdminLayout />}>
					<Route
						path='/'
						element={<Home />}
					/>
					<Route
						path='/publisher'
						element={<Publishers />}
					/>
					<Route
						path='/setting/change-password'
						element={<ChangePassword />}
					/>
					<Route
						path='/publisher/delete'
						element={<DeleteModal />}
					/>
					<Route
						path='/publisher/add'
						element={<AddPublishers />}
					/>
					<Route
						path='/publisher/:id'
						element={<ViewPublisher />}
					/>
					<Route
						path='/publisher/edit/:id'
						element={<EditPublishers />}
					/>
					<Route
						path='/transaction'
						element={<Transactions />}
					/>
					<Route
						path='/settlement'
						element={<Settlements />}
					/>
					<Route
						path='/settlement/settle/:id'
						element={<Settle />}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default AllRoutes;
