import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

// get all transactions
export const getAllTransactions = createAsyncThunk(
  "transactions/getAllTransactions",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getTransactions();
      if (response.status === 200) {
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  }
);

// add article price
export const addArticlePrice = createAsyncThunk(
  "transactions/addArticlePrice",
  async ({ publisherId, data, onReset }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addArticlePrice(publisherId, data);
      if (response.status === 200) {
        onReset();
        toast.success("Article Price Updated!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      toast.error(error?.response?.data?.ERROR?.message, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

const initialState = {
  allTransactions: [],
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.allTransactions = action.payload;
      })
      .addCase(getAllTransactions.rejected, (state) => {
        state.loading = false;
      })

      .addCase(addArticlePrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(addArticlePrice.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addArticlePrice.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default transactionSlice.reducer;
