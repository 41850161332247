import React, { useEffect, useMemo, useState } from "react";
import { SaveBtn, TopHeading, Wrapper } from "../../../common/index.style";
import { CustomContainer } from "./index.style";
import {
  Col,
  Form,
  ColorPicker,
  Input,
  InputNumber,
  Checkbox,
  Radio,
  Row,
  Select,
  Divider,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addpublisher } from "../../../redux/publisherSlice";
import {
  convertColorObjectToHex,
  hsvToHexWithAlpha,
  textSizeTags,
} from "../../../utils/helper";
import { AiOutlinePercentage } from "react-icons/ai";
import { TbCurrencyDollar } from "react-icons/tb";
import lookup from "country-code-lookup";
import { getSupportedCurrencies } from "../../../redux/currencySlice";
import { TEXTS } from "../../../utils/text";

const AddPublishers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading: isPublisherLoading } = useSelector(
    (store) => store.publisher
  );
  const { loading: isSupportedCurrenciesLoading, supportedCurrencies } =
    useSelector((store) => store.currency);

  useEffect(() => {
    dispatch(getSupportedCurrencies());
  }, []);

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onFinish = (values) => {
    const paymentButtonColor =
      values?.paymentButtonColor?.metaColor?.originalInput;
    const paymentTextColor = values?.textColor?.metaColor?.originalInput;
    const actaPromptText =
      values?.actaPromptText === undefined ||
      values?.actaPromptText === TEXTS.EMPTY_TEXT
        ? TEXTS.DEFAULT_ACTA_PROMPT_TEXT
        : values?.actaPromptText;
    const actaButtonText =
      values?.actaButtonText === undefined ||
      values?.actaButtonText === TEXTS.EMPTY_TEXT
        ? TEXTS.DEFAULT_ACTA_BUTTON_TEXT
        : values?.actaButtonText;

    const publisherData = {
      name: values?.publisherName,
      email: values?.email,
      website: values?.website,
      userFirstName: values?.firstName,
      userLastName: values?.lastName,
      country: values?.country,
      articlePrice: values?.articlePrice,
      revShare1: {
        percentage: values?.revShare,
        minPrice: values?.miniPrice,
      },
      revShare2: {
        percentage: values?.revShare2 ?? null,
        minPrice: values?.lowPrice2 ?? null,
        maxPrice: values?.highPrice2 ?? null,
      },
      revShare3: {
        percentage: values?.revShare3 ?? null,
        minPrice: values?.lowPrice3 ?? null,
        maxPrice: values?.highPrice3 ?? null,
      },
      stripe: {
        currency: values?.currency,
      },
      // abTest: values?.abTest === "Yes" ? true : false,
      active: values?.active === "Yes" ? true : false,
      ghostAPI: {
        apiKey: values?.apiKey,
        apiURL: values?.apiURL,
      },
      style: {
        paywallButtonClass: values?.paywallButtonClass,
        paywallPadding: values?.paywallPadding,
        paywallButtonColor: convertColorObjectToHex(values?.paywallButtonColor),
        paywallButtonTextColor: convertColorObjectToHex(
          values?.paywallButtonTextColor
        ),
        paywallButtonTextSize: values?.paywallButtonTextSize ?? null,
        paywallContainerClass: values?.paywallContainerClass,
        paywallPromptTextClass: values?.paywallPromptTextClass,
        paywallPromptTextColor: convertColorObjectToHex(
          values?.paywallPromptTextColor
        ),
        paywallPromptTextSize: values?.paywallPromptTextSize ?? null,
        paywallButtonTag: values?.paywallButtonTag ?? null,
        paywallButtonWrapperClass: values?.paywallButtonWrapperClass ?? null,
        ghContentClass: values?.ghContentClass ?? null,
        isButtonNext: values?.isButtonNext ?? false,
      },
      primaryColor: paymentButtonColor
        ? typeof paymentButtonColor === "object" &&
          Object.keys(paymentButtonColor)?.length > 0
          ? hsvToHexWithAlpha(
              paymentButtonColor?.h,
              paymentButtonColor?.s,
              paymentButtonColor?.v,
              paymentButtonColor?.a
            )
          : "#" + paymentButtonColor
        : "#4209EE",
      secondaryColor: paymentTextColor
        ? typeof paymentTextColor === "object" &&
          Object.keys(paymentTextColor)?.length > 0
          ? hsvToHexWithAlpha(
              paymentTextColor?.h,
              paymentTextColor?.s,
              paymentTextColor?.v,
              paymentTextColor?.a
            )
          : "#" + paymentTextColor
        : "#FFFFFF",
      actaPromptText: actaPromptText,
      actaButtonText: actaButtonText,
    };
    dispatch(addpublisher({ publisherData, navigate }));
  };

  const countries = useMemo(() => {
    const countries = lookup.countries;
    const countryOptions = [];
    countries.forEach((country) => {
      countryOptions.push({
        label: country.country,
        value: country.iso2,
      });
    });
    return countryOptions;
  }, []);

  const currencies = useMemo(() => {
    const currencyOptions = supportedCurrencies.map((currency) => {
      return {
        label: currency.toUpperCase(),
        value: currency.toLowerCase(),
      };
    });
    return currencyOptions;
  }, [supportedCurrencies]);

  const paywallButtonTags = [
    {
      label: "a",
      value: "a",
    },
    {
      label: "button",
      value: "button",
    },
    {
      label: "div",
      value: "div",
    },
  ];

  return (
    <Wrapper>
      <CustomContainer>
        <Form onFinish={onFinish} layout="vertical">
          <div className="publisherForm">
            <TopHeading>Add Publishers</TopHeading>
            <div>
              <SaveBtn
                htmlType="button"
                // onClick={() => navigate("/add_publisher/view")}
              >
                {isPublisherLoading ? "loading..." : "Save"}
              </SaveBtn>
            </div>
          </div>
          <Row justify="space-between" gutter={16} className="userInputs">
            <Col>
              <Form.Item
                name="publisherName"
                label="Publisher Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="website"
                label="Website"
                rules={[
                  { required: true, message: "required" },
                  {
                    type: "url",
                    message: "Please enter a valid URL",
                  },
                  {
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { type: "email", message: "email is not valid" },
                  { required: true, message: "required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  showSearch
                  // placeholder='Select a country'
                  optionFilterProp="children"
                  // onChange={onChange}
                  // onSearch={onSearch}
                  filterOption={filterOption}
                  options={countries}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  loading={isSupportedCurrenciesLoading}
                  options={currencies}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 4 }}>
              <Form.Item
                name="active"
                label="Active?"
                rules={[{ required: true, message: "required" }]}
              >
                <Radio.Group>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="articlePrice"
                label="Default Article price"
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="revShare"
                label="Rev share "
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber prefix={<AiOutlinePercentage />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item
                name="miniPrice"
                label="Minimum price"
                rules={[{ required: true, message: "required" }]}
              >
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={26}>
            <Col lg={{ span: 6 }}>
              <Form.Item name="revShare2" label="Rev share 2 (optional)">
                <InputNumber prefix={<AiOutlinePercentage />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="lowPrice2" label="Low price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="highPrice2" label="High price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="revShare3" label="Rev share 3 (optional)">
                <InputNumber prefix={<AiOutlinePercentage />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="lowPrice3" label="Low price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 9 }}>
              <Form.Item name="highPrice3" label="High price">
                <InputNumber prefix={<TbCurrencyDollar />} />
              </Form.Item>
            </Col>

            <Col lg={{ span: 12 }}>
              <Form.Item
                name="apiKey"
                label="API Key"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={{ span: 12 }}>
              <Form.Item
                name="apiURL"
                label="API URL"
                rules={[
                  { required: true, message: "required" },
                  {
                    type: "url",
                    message: "Please enter a valid URL",
                  },
                  {
                    type: "string",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <h2>Styles</h2>
          <Row gutter={28}>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallContainerClass"
                label="Paywall container class"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonClass"
                label="Paywall button class"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallPromptTextClass"
                label="Paywall prompt text class"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallPromptTextSize"
                label="Paywall prompt text size"
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={textSizeTags}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={28} style={{ marginTop: 10 }}>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonWrapperClass"
                label="Paywall button wrapper class"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonTag"
                label="Paywall button tag"
                rules={[{ required: true, message: "required" }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={paywallButtonTags}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonTextSize"
                label="Paywall button text size"
              >
                <InputNumber min={1} suffix={<p>px</p>} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="paywallPadding" label="Paywall Padding">
                <InputNumber min={1} suffix={<p>px</p>} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={28} style={{ marginTop: 10 }}>
            <Col lg={{ span: 6 }}>
              <Form.Item name="ghContentClass" label="Gh content class">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="actaPromptText" label="Acta prompt text">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="actaButtonText" label="Acta button text">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={{ span: 4 }}>
              <Form.Item
                name="isButtonNext"
                valuePropName="checked"
                label="Button alternative position"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={28} style={{ marginTop: 10 }}>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallButtonTextColor"
                label="Paywall button text color"
              >
                <ColorPicker allowClear showText defaultValue={null} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item name="paywallButtonColor" label="Paywall button color">
                <ColorPicker allowClear showText defaultValue={null} />
              </Form.Item>
            </Col>

            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paywallPromptTextColor"
                label="Paywall prompt text color"
              >
                <ColorPicker allowClear defaultValue={null} showText />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="paymentButtonColor"
                initialValue="#4209EE"
                label="Payment button color"
              >
                <ColorPicker defaultValue="#4209EE" showText />
              </Form.Item>
            </Col>
            <Col lg={{ span: 6 }}>
              <Form.Item
                name="textColor"
                initialValue="#FFFFFF"
                label="Outline / text color"
              >
                <ColorPicker showText />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomContainer>
    </Wrapper>
  );
};

export default AddPublishers;
