import styled from "styled-components";

export const CustomContainer = styled("div")`
  padding: 1em 0;
  .publisherForm {
    display: flex;
    align-items: center;
    button {
      margin-bottom: 2em;
    }
  }
  .ant-form-item {
    margin-bottom: 0.7em;
  }
  .userInputs {
    .ant-input {
      max-width: 190px;
    }
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #434343 !important;
    box-shadow: none !important;
  }
  .ant-select {
    display: flex;
    align-items: center;
    &:hover {
      .ant-select-selector {
        background: #ffffff;
        border: 1px solid #434343 !important;
      }
    }
    &:focus {
      .ant-select-selector {
        background: #ffffff;
        border: 1px solid #434343 !important;
      }
    }

    .ant-select-selector {
      display: flex;
      align-items: center;
      max-width: 600px;
      width: 100%;
      height: 40px;
      background: #ffffff;
      border: 1px solid #434343;
      border-radius: 5px;
      font-size: 1.2em;
      // margin-bottom: 1.5em;

      .ant-select-selection-search {
        input {
          height: 100%;
        }
      }
    }
  }
  .ant-input-number-affix-wrapper {
    .ant-input-number-handler-wrap {
      display: none;
    }
    input {
      height: 40px;
      appearance: none;
      -moz-appearance: textfield;
      padding: 0 !important;
    }
  }
  .ant-input-number-affix-wrapper,
  .ant-input {
    max-width: 600px;
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #434343;
    border-radius: 5px;
    font-size: 1.2em;
    // margin-bottom: 1.5em;
    &:hover {
      background: #ffffff;
      border: 1px solid #434343;
    }
  }
  .userInputs {
    .ant-input {
      max-width: 180px;
    }
  }
  .ant-form-item-label {
    label {
      color: #000000;
      font-size: 1.2em;
    }
  }
  @media (max-width: 1450px) {
    & {
      .userInputs {
        .ant-input {
          max-width: 170px;
        }
      }
      .ant-row-space-between {
        justify-content: start;
      }
    }
  }
`;
