import styled from "styled-components";

export const AsideContainer = styled("div")`
  width: 230px;
  padding: 1em 2em;
  height: 100%;
  position: fixed;
  bottom: 0;
  background: #434343;
`;

export const Logo = styled("div")`
  padding: 0.4em 1.3em 1.5em;
  margin-bottom: 2em;
  text-align: center;
  h5 {
    font-size: 2.3em;
    font-weight: 700;
    color: #d9d9d9;
  }
`;
