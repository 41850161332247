import styled from "styled-components";

export const ChangePasswordRoot = styled("div")``;
export const HeadContainer = styled("div")`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;
export const HeadTitle = styled("h2")`
	color: black;
	text-align: center;
	font-size: 42px;
	align-self: center;
`;
export const Button = styled("button")`
	background: ${(props) => props.theme.button};
	width: 200px;
	height: 40px;
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 0px;
	right: 0px;
`;
