import React, { useRef, useState } from "react";
import { CustomTable } from "./index.style";
import { Link } from "react-router-dom";
import moment from "moment";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { deletePublisher } from "../../redux/publisherSlice";
import { handleMinus } from "../../utils/helper";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const PublisherDataTable = ({ publishers, loading }) => {
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (pid) => {
    modal.confirm({
      title: "Are you sure you want to delete this publisher?",
      onOk: async () => {
        await dispatch(deletePublisher({ publisherId: pid }));
      },
    });
  };

  const dataSource = [];
  publishers?.map((p) => {
    dataSource.push({
      key: p?.id,
      created: moment(p?.createdAt).format("l"),
      publisher: p?.name,
      website: p?.website,
      email: p?.email,
      share: p?.revShare1?.percentage + "%",
      share2: p?.revShare2?.enabled
        ? `${p?.revShare2?.percentage} % ($${p?.revShare2?.minPrice} - $${p?.revShare2?.maxPrice})`
        : "N/A",
      share3: p?.revShare3?.enabled
        ? `${p?.revShare3?.percentage} % ($${p?.revShare3?.minPrice} - $${p?.revShare3?.maxPrice})`
        : "N/A",
      article: "$" + p?.articlePrice.toFixed(2),
      active: p?.active ? "Yes" : "No",
      price: p?.revShare1?.minPrice
        ? "$" + p?.revShare1?.minPrice?.toFixed(2)
        : "N/A",
      sold: "$" + p?.sales?.toFixed(2),
      settled: "$" + p?.settled?.toFixed(2),
      owed: handleMinus(+p?.balance),
      action: (
        <div>
          <Link to={`/publisher/edit/${p?.id}`}>[edit]</Link>
          <br />
          <Link to={`/settlement/settle/${p?.id}`}>[settle]</Link>
          <br />
          <span className="actionBtn" onClick={() => handleDelete(p?.id)}>
            [delete]
          </span>
          <br />
          <Link to={`/publisher/${p?.id}`}>[view]</Link>
        </div>
      ),
    });
  });

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      width: 100,
      ...getColumnSearchProps("created"),
      sorter: (a, b) => {
        return a.created.localeCompare(b.created); // Use localeCompare for string comparison
      },
    },
    {
      title: "Publisher",
      dataIndex: "publisher",
      key: "publisher",
      width: 100,

      ...getColumnSearchProps("publisher"),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      width: 100,

      ...getColumnSearchProps("website"),
    },
    {
      title: " Email",
      dataIndex: "email",
      key: "email",
      width: 100,

      ...getColumnSearchProps("email"),
    },
    {
      title: "Rev share",
      dataIndex: "share",
      key: "share",
      width: 200,
      ...getColumnSearchProps("share"),
    },
    {
      title: "Rev share 2",
      dataIndex: "share2",
      key: "share2",
      width: 300,
      ...getColumnSearchProps("share2"),
    },
    {
      title: "Rev share 3",
      dataIndex: "share3",
      key: "share3",
      width: 300,
      ...getColumnSearchProps("share3"),
    },
    {
      title: "Article price",
      dataIndex: "article",
      key: "article",
      width: 200,

      ...getColumnSearchProps("article"),
    },
    {
      title: "Min Price",
      dataIndex: "price",
      key: "price",
      width: 200,

      ...getColumnSearchProps("price"),
    },
    {
      title: "$ Sold to date",
      dataIndex: "sold",
      key: "sold",
      width: 200,
      ...getColumnSearchProps("sold"),
    },
    {
      title: "$ settled to date",
      dataIndex: "settled",
      key: "settled",
      width: 250,
      ...getColumnSearchProps("settled"),
    },
    {
      title: "$ owed to publisher",
      dataIndex: "owed",
      key: "owed",
      width: 300,
      ...getColumnSearchProps("owed"),
    },
    {
      title: "Active?",
      dataIndex: "active",
      key: "active",
      ...getColumnSearchProps("active"),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <>
      {contextHolder}
      <CustomTable
        scroll={{
          x: 2000,
        }}
        pagination={{ pageSize: 50 }}
        loading={loading}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </>
  );
};

export default PublisherDataTable;
