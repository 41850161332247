import React from "react";
import { AsideContainer, Logo } from "./index.style";
import Navigation from "../sideNavigation";

const Sidebar = (props) => {
  return (
    <AsideContainer>
      <Logo>
        <h5>Acta</h5>
      </Logo>
      <Navigation data={props.data} />
    </AsideContainer>
  );
};

export default Sidebar;
