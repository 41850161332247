import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { toast } from "react-toastify";

// add settlement
export const addSettlement = createAsyncThunk(
  "settlement/addSettlement",
  async (
    { settlementData, setConfirm, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addSettlement(settlementData);
      if (response.status === 200) {
        setConfirm(true);
        toast.success("Amount settled successfully!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      toast.error(error?.response?.data?.ERROR?.message, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

// get all settlement
export const getAllSettlements = createAsyncThunk(
  "settlement/getAllSettlements",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllSettlements();
      if (response.status === 200) {
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      // toast.error(error?.response?.data?.errors?.[0]?.msg, {
      //   position: "top-right",
      //   autoClose: 3000,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
  }
);

// add settlement
export const deleteSettlement = createAsyncThunk(
  "settlement/deleteSettlement",
  async ({ settlementId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.deleteSettlement(settlementId);
      if (response.status === 200) {
        dispatch(getAllSettlements());
        toast.success("Settlement deleted successfully!", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
        return response?.data?.RESPONSE;
      }
    } catch (error) {
      toast.error(error?.response?.data?.ERROR?.message, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  }
);

const initialState = {
  loading: false,
  allSettlements: [],
  settlement: {},
};

const settlementSlice = createSlice({
  name: "settlement",
  initialState,
  reducers: {
    setSettlement(state, action) {
      state.settlement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addSettlement.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSettlement.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addSettlement.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAllSettlements.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSettlements.fulfilled, (state, action) => {
        state.loading = false;
        state.allSettlements = action.payload;
      })
      .addCase(getAllSettlements.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default settlementSlice.reducer;
export const { setSettlement } = settlementSlice.actions;
