export const passwordLengthValidator = () => (_, value) => {
	return new Promise((resolve, reject) => {
		if (value && value.length < 6) {
			reject("Password must be at least 6 characters");
		}
		resolve();
	});
};
export const passwordConfirmValidator = (form) => (_, _value) => {
	return new Promise((resolve, reject) => {
		const value = form.getFieldValue("newPassword");
		if (value !== _value) {
			return reject("Passwords do not match");
		}
		resolve();
	});
};
