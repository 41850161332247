import { Button, Form } from "antd";
import styled from "styled-components";

export const MarkingArea = styled("div")`
  // max-width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fce5cd;
  height: 130px;
  padding: 1em 2em;
  h5 {
    font-size: 1.5em;
    font-weight: 700;
  }
  p {
    color: #000000;
    font-weight: 500;
    font-size: 1.3em;
    font-style: italic;
    margin-bottom: 0;
  }
  @media (max-width: 1400px) {
    & {
      h5 {
        font-size: 1.2em;
      }
      p {
        font-size: 1.1em;
      }
    }
  }
`;

export const CodeContainer = styled("div")`
  min-height: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 1em 2em;
  margin-top: 1em;
  background: #efefef;
  overflow: auto;

  .codeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .copyCode {
    cursor: pointer;
  }
  .installationCode {
    script {
      width: 100%;
    }
  }
`;

export const SaveBtn = styled(Button)`
  width: 200px;
  padding: 0.5em 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 1.3em;
  font-weight: 700;
  background: #c9daf8;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: #000000;
  height: 40px;
  margin-top: 2.125rem;
  &:hover {
    color: #000000 !important;
  }
  @media (max-width: 1500px) {
    & {
      width: 180px;
      padding: 0.5em 1em;
      font-size: 1.2em;
    }
  }
  @media (max-width: 1400px) {
    & {
      width: 160px;
      padding: 0.5em 1em;
      font-size: 1.1em;
    }
  }
`;
