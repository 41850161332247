import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 2em 3em;
  @media (max-width: 1400px) {
    & {
      padding: 2em 1em;
    }
  }
`;
export const TopHeading = styled("h1")`
  margin: 1em auto;
  width: 400px;
  text-align: center;
  font-weight: 700;
  font-size: 2em;
  line-height: 1.2;
  margin-bottom: 2em;
  @media (max-width: 1300px) {
    width: 250px;
    & {
      font-size: 1.7em;
    }
  }
`;
export const AddButton = styled(Link)`
  width: 200px;
  height: 40px;
  padding: 0.5em 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 1.3em;
  font-weight: 700;
  background: #c9daf8;
  border-radius: 4px;
  color: #000000;
  &:hover {
    color: #000000;
  }
  @media (max-width: 1500px) {
    & {
      width: 180px;
      padding: 0.5em 1em;
      font-size: 1.2em;
    }
  }
  @media (max-width: 1400px) {
    & {
      width: 160px;
      padding: 0.5em 1em;
      font-size: 1.1em;
    }
  }
`;

export const DownloadButton = styled.span`
  width: 200px;
  height: 40px;
  padding: 0.5em 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 1.3em;
  font-weight: 700;
  background: #c9daf8;
  border-radius: 4px;
  color: #000000;
  &:hover {
    color: #000000;
  }
  @media (max-width: 1500px) {
    & {
      width: 180px;
      padding: 0.5em 1em;
      font-size: 1.2em;
    }
  }
  @media (max-width: 1400px) {
    & {
      width: 160px;
      padding: 0.5em 1em;
      font-size: 1.1em;
    }
  }
`;
export const SaveBtn = styled("button")`
  width: 200px;
  padding: 0.5em 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-size: 1.3em;
  font-weight: 700;
  background: #c9daf8;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: #000000;
  &:hover {
    color: #000000;
  }
  @media (max-width: 1500px) {
    & {
      width: 180px;
      padding: 0.5em 1em;
      font-size: 1.2em;
    }
  }
  @media (max-width: 1400px) {
    & {
      width: 160px;
      padding: 0.5em 1em;
      font-size: 1.1em;
    }
  }
`;

export const Filter = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  .ant-input {
    margin-right: 1em;
    background: #c9daf8;
    max-width: 600px;
    height: 60px;
    font-size: 1.3em;
    border: 2px solid #43434350;
    &::placeholder {
      color: #000000;
    }
  }
  @media (max-width: 1300px) {
    & {
      .ant-input {
        background: #c9daf8;
        max-width: 600px;
        height: 38px;
        font-size: 1.1em;
        border: 2px solid #43434350;
      }
    }
  }
`;
export const SoldDate = styled("div")`
  font-weight: 500;
  font-size: 1.5em;
  margin: 0.5em 0 2em;

  .ant-input-number-affix-wrapper {
    .ant-input-number-handler-wrap {
      display: none;
    }
    input {
      height: 40px;
      appearance: none;
      -moz-appearance: textfield;
      padding: 0 !important;
    }
  }
  .ant-input-number-affix-wrapper,
  .ant-input {
    max-width: 600px;
    width: 100%;
    height: 43px;
    background: #ffffff;
    border: 1px solid #434343;
    border-radius: 5px;
    font-size: 1.2em;
    // margin-bottom: 1.5em;
    &:hover {
      background: #ffffff;
      border: 1px solid #434343;
    }
  }
  .ant-form-item-label {
    label {
      color: #000000;
      font-size: 1.2em;
    }
  }
  @media (max-width: 1400px) {
    & {
      font-size: 1.2em;
    }
  }
`;
