import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";

export const getSupportedCurrencies = createAsyncThunk(
  "currency/getSupportedCurrencies",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await ApiRequests.getSupportedCurrencies();
      if (status === 200) {
        return data?.supportedCurrencies;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  supportedCurrencies: [],
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupportedCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSupportedCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.supportedCurrencies = action.payload;
      })
      .addCase(getSupportedCurrencies.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default currencySlice.reducer;
