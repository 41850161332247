export const basePath =
	process.env.REACT_APP_BASE_PATH || "http://127.0.0.1:8000/api/v1/";

export const APIurls = {
	// auth
	admin: "auth/admin",
	adminProfile: "admin",

	// publishers
	publishers: "publishers",

	// settlements
	settlements: "settlements",

	//transactions
	transactions: "transactions",

	// articles
	articles: "articles",

	//currencies
	currencies: "/stripe/currencies",
	updatePassword: "/admin/auth/password",
};
