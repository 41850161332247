import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button } from "antd";

import { ChangePasswordRoot, HeadTitle, HeadContainer } from "./setting.style";
import { FormItemArray } from "./formFields";
import { onClickHandler, onFinish } from "./settingHandler";

const ChangePassword = () => {
	const [form] = Form.useForm();
	
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const mapPasswordInputFormRendering = FormItemArray.map((item, index) => (
		<Form.Item
			label={item.label}
			name={item.name}
			key={index}
			rules={[
				{ required: true, message: item.message },
				{ validator: item.validator && item.validator(form) },
			]}
		>
			<Input.Password />
		</Form.Item>
	));

	const formSubmitHandler = (dispatch) => (values) => {
		onFinish(dispatch, navigate, values);
	};

	const buttonCustomizeStyle = {
		width: 200,
		height: 40,
		backgroundColor: `rgb(201, 218, 248)`,
		position: `absolute`,
		right: `0px`,
	};

	return (
		<ChangePasswordRoot>
			<HeadContainer>
				<HeadTitle>Edit Password</HeadTitle>
				<Button
					style={buttonCustomizeStyle}
					onClick={() => {
						onClickHandler(form);
					}}
				>
					Save
				</Button>
			</HeadContainer>
			<Form
				name='settingForm'
				onFinish={formSubmitHandler(dispatch)}
				layout='vertical'
				form={form}
				initialValues={{ remember: true }}
				style={{ maxWidth: 400 }}
				autoComplete='off'
			>
				{mapPasswordInputFormRendering}
			</Form>
		</ChangePasswordRoot>
	);
};

export default ChangePassword;
