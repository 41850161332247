import React, { useRef, useState } from "react";
import { CustomTable } from "./index.style";
import moment from "moment/moment";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const TransactionDataTable = ({ transactions, loading }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const dataSource = [];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex] !== undefined) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return false;
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  transactions?.map((transaction, index) => {
    dataSource.push({
      key: transaction?.id,
      created: moment(transaction?.createdAt).format("l"),
      user: transaction?.user?.name,
      website: transaction?.publisher?.website,
      email: transaction?.user?.email,
      publisher: transaction?.publisher?.name,
      article: transaction?.articleURL,
      transacted: "$" + transaction?.amount.toFixed(2),
      fees: "$" + transaction?.platformFee.toFixed(2),
      share: transaction?.revShare + "%",
      netRev: "$" + transaction?.netRev.toFixed(2),
    });
  });
  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      ...getColumnSearchProps("created"),
      sorter: (a, b) => {
        return a.created.localeCompare(b.created); // Use localeCompare for string comparison
      },
    },
    {
      title: "User ",
      dataIndex: "user",
      key: "user",
      width: 100,
      ...getColumnSearchProps("user"),
      sorter: (a, b) => {
        return a.user.localeCompare(b.user); // Use localeCompare for string comparison
      },
    },
    {
      title: "User email ",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => {
        return a.email.localeCompare(b.email); // Use localeCompare for string comparison
      },
    },
    {
      title: "Publisher",
      dataIndex: "publisher",
      key: "publisher",
      ...getColumnSearchProps("publisher"),
    },
    {
      title: "Article URL",
      dataIndex: "article",
      key: "article",
      ...getColumnSearchProps("article"),
    },
    {
      title: "$ Transacted",
      dataIndex: "transacted",
      key: "transacted",
      ...getColumnSearchProps("transacted"),
    },
    {
      title: "Processing fees",
      dataIndex: "fees",
      key: "fees",
      ...getColumnSearchProps("fees"),
    },
    {
      title: "Rev share",
      dataIndex: "share",
      key: "share",
      ...getColumnSearchProps("share"),
      sorter: (a, b) => {
        return a.share.localeCompare(b.share); // Use localeCompare for string comparison
      },
    },
    {
      title: (
        <>
          <strong>Net rev</strong> <br />
          <span>(rev share * price)</span>
        </>
      ),
      dataIndex: "netRev",
      key: "netRev",
      width: 200,
      ...getColumnSearchProps("netRev"),
      sorter: (a, b) => {
        return a.netRev.localeCompare(b.netRev); // Use localeCompare for string comparison
      },
    },
  ];

  return (
    <CustomTable
      scroll={{
        x: 1300,
      }}
      pagination={false}
      loading={loading}
      bordered
      dataSource={dataSource}
      columns={columns}
    />
  );
};

export default TransactionDataTable;
